export const environment = {
    production: false,
    hotjar: {
        enabled: true,
        id: 3758385
    },
    baseUrl: 'https://costagridqaentity.costa.express',
    apiUrl: {
        entity: 'https://costagridqaentity.costa.express',
        machine: 'https://costagridqamachine.costa.express',
        user: 'https://costagridqauser.costa.express',
        salesProcessor: 'https://eun-grd-qa-wa-sales-processor-api.azurewebsites.net',
        event: 'https://costagridqaevents.costa.express',
        soxAuditLog: 'https://costagridqaaudit-logs.costa.express',
        alarmProcessor: 'https://eun-grd-qa-wa-alarm-processor-api.azurewebsites.net',
        signalR: 'https://costagridqasignalr.costa.express',
        remoteUpdate: 'https://remote-update-tasks-management-qa.azurewebsites.net',
        azureAppConfig: 'Endpoint=https://grid-ui-qa.azconfig.io;Id=KHQJ;Secret=m5GjxECm+Tc5p5pnslptfnEPhecLxuFxOGayRH/63C0=',
        remoteUpdatePackage: 'https://remote-update-packages-management-qa.azurewebsites.net'
    },
    cookieName: 'CE_',
    env: 'qa',
    version: '2.39',
    domainName: "costagridqa.costa.express",
    siteKeyCaptcha: '6LfDn5ocAAAAAKkog37xYn2bqXtTEHn-r1v72sdy',
    msal: {
        authority: "https://login.microsoftonline.com/6511efd3-03fb-449b-9a07-00ccbcc060ae/",
        clientId: "83713ab9-3872-4036-ae7a-fa933ccdcf00",
        redirectUrl: "https://costagridqa.costa.express/callback",
        postLogoutRedirectUri: "https://costagridqa.costa.express/",
        loginscope: "openid email profile",
        apiscope: "api://e2b9d79e-45e4-492b-908b-6a950738c89f/access_as_user",
        costaExpressUrl: "https://idam-dev.azurewebsites.net/"
    },
};
